import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: 'icommunication',
    data: {
      breadcrumbLabel: 'general.menu.Icommunication',
      verticalIndex: 0,
    },
    loadChildren: () =>
      import('../app/icommunication/icommunication.module').then(
        (m) => m.IcommunicationModule,
      ),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'icommunication',
  },
  {
    path: 'user-settings',
    loadChildren: () =>
      import(
        '../../../c4p-portal-util/src/lib/user-settings/user-settings.module'
      ).then((m) => m.UserSettingsModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
